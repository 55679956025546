// import Aux from '../../hoc/Auxiliary/Auxiliary';
// import { useState, useEffect } from 'react';
import classes from './Streak.module.css';

const Streak = (props) => {

    // const [classList, setClassList] = useState([]);

    // useEffect(() => {
    //     setClassList([classes.FadeIn]);
    //     setTimeout(() => {
    //         setClassList([classes.FadeOut]);
    //     }, 10);
    // }, [])

    return (
            <div className={classes.Streak}>
                <div >Current Streak: {props.currentStreak}</div>
                <div>Max Streak: {props.maxStreak}</div>
                {/* <div >Current Streak: <span >{props.currentStreak}</span></div> */}
            </div>


    );
}

export default Streak;