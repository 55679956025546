import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import DailyStreak from '../../../components/Streak/DailyStreak/DailyStreak';

const Toolbar = (props) => (

    <header className={classes.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked} />
        <div className={classes.Logo}>
            <Logo />
            {/* <p>&nbsp;&nbsp;&nbsp;</p> */}
            <DailyStreak daysInStreak={props.daysInStreak}/>
        </div>
        <nav className={classes.DesktopOnly}>
            <NavigationItems 
            toggleMulti={props.toggleMulti} setToggleMulti={props.setToggleMulti}
            toggleOrigin={props.toggleOrigin} setToggleOrigin={props.setToggleOrigin} 
            toggleTranscribe={props.toggleTranscribe} setToggleTranscribe={props.setToggleTranscribe}/> 
        </nav>
    </header>
);


  
export default (Toolbar);
