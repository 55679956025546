// import Aux from '../../hoc/Auxiliary/Auxiliary';
// import { useState, useEffect } from 'react';
// import { Fragment } from 'react';
import classes from './StreakSummary.module.css';
import flameY from '../../../../assets/images/flame-icon-y.jpg';
import flameN from '../../../../assets/images/flame-icon-n.jpg';

const StreakSummary = (props) => {

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const arr = [6, 5, 4, 3, 2, 1, 0];
    // console.log('arr: ', arr);

    const dailyStreak = localStorage.getItem("daily-streak") ?? [];
    const daysContent = arr.map(i => {
        let date = new Date();
        date.setDate(date.getDate() - i);
        const day = date.getDay();
        const dayOfWeek = days[day];
        // console.log('dayOfWeek: ', dayOfWeek);
        // console.log('day: ', day);
        const dateString = date.toLocaleDateString('sv-SW');
        // console.log('dateString: ', dateString);

        if (dailyStreak.includes(dateString)) {
            // console.log('date in streak: ', dateString);
            return (
                <div key={i} className={classes.Item}>
                    <div><img src={flameY} alt="completed" style={{width: '25px'}}/></div>
                    <div className={classes.DayLabel}>{dayOfWeek}</div>
                </div>
            );
        }
        else {
            return (
                <div  key={i} className={classes.Item}>
                    <div><img src={flameN} alt="not-completed" style={{width: '25px'}}/></div>
                    <div className={classes.DayLabel}>{dayOfWeek}</div>

                </div>
            ); 
        }


    });
    

    return (
    <div className={classes.StreakSummary}>
        {daysContent}    
    </div>

    );
}

export default StreakSummary;