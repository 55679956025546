import classes from './Quotes.module.css';
import React, { useEffect,
    //  useRef, 
    useCallback } from "react";
import { useState } from 'react';
import { 
    // arraysMatch, 
    shuffle, getRandomSubarray } from '../../../helpers/helpers';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import ReactFlagsSelect from 'react-flags-select';
import { googleLanguageCodes, languageLabels, AWS_API, multiChoiceGuess } from '../../../config/config';
import axios from 'axios';
import Socials from '../../../hoc/Socials/Socials';
// import Coffee from '../../hoc/Coffee/Coffee';
import Streak from '../../Streak/Streak';
import Toggle from 'react-toggle';
import Modal from '../../../UI/Modal/Modal';
import { Link } from 'react-router-dom';
import TopDrawer from '../../../UI/TopDrawer/TopDrawer';

var stringSimilarity = require("string-similarity");


const Quotes = (props) => {

    // state
    const [selectedLine, setSelectedLine] = useState();
    const [translatedText, setTranslatedText] = useState();
    const [showTranslatedText, setShowTranslatedText] = useState(false);

    // UI state
    const [modalMessage, setModalMessage] = useState();
    const [multiAnswers, setMultiAnswers] = useState();
    const [correctMulti, setCorrectMulti] = useState();
    const [incorrectMulti, setIncorrectMulti] = useState();
    const [url, setUrl] = useState();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    // show/hide state
    const [showSteps, setShowSteps] = useState({1: true, 2: true, 3: true, 4: true});

    // user preferences state
    const [language, setLanguage] = useState('it');
    const [accentOnly, setAccentOnly] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState();
    const [difficultyEasy, setDifficultyEasy] = useState(false)

    // history state for checks on changes
    const [languageHistory, setLanguageHistory] = useState('it');
    const [previousType, setPreviousType] = useState();
    const [accentOnlyHistory, setAccentOnlyHistory] = useState(false);

    // data state
    const [lineInfo, setLineInfo] = useState();
    
    // streak state
    const [showPromptUserModal, setShowPromptUserModal] = useState(false);
    
    // session counters
    const [pristine, setPristine] = useState(true);

    // get width of device user is on
    // const innerWidth = window.innerWidth;
    // const mobileScreen = (innerWidth < 550) ? true : false;
    // console.log('mobileScreen: ', mobileScreen);

    // get data from props and set some default quote types
    useEffect(() => {

        // get unique list of types available once we've got data from props
        if (props.lines) {
            const availableTypes = props.lines.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i);
    
            // remove a couple of types to make it obvious which are selected/unselected
            availableTypes.splice(availableTypes.indexOf('quote'),1);
            availableTypes.splice(availableTypes.indexOf('book'),1);
    
            setSelectedTypes(availableTypes);
        }

    }, [props.lines]);
    

    const getNewLineId = (prevType) => {
        // console.log('props.lines: ', props.lines);
        
        const completedLines = JSON.parse(localStorage.getItem("completedLines")) ?? [];
        // console.log('completedLines, numLines, prevType: ', completedLines, props.lines.length, prevType);

        // get options for quotes which are of type selected by user and haven't been completed yet
        let options = props.lines.filter(obj => obj.type !== prevType && !completedLines.includes(obj.id) && selectedTypes.includes(obj.type))
        // console.log('options: ', options);
        if (options && options.length > 0) {
            console.log(props.lines.length, options.length, options.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i));
        }

        // options length 0 here means there are no props.lines left of a different type to the one previously completed, so just look for any in selectedTypes which haven't been done
        if (options.length === 0) {
            // console.log('no options of different type left, opening up all options again');
            options = props.lines.filter(obj => !completedLines.includes(obj.id) && selectedTypes.includes(obj.type));
        }

        // options length 0 here means that user has completed all props.lines of all selected types - reset local storage
        if (options.length === 0) {
            // console.log('no options of any type left, clearing local storage and starting again');
            // alert('All quotes of selected types have been played, resetting selection');
            setModalMessage('All quotes of selected types have been played, resetting selection');
            const allAvailableTypes = props.lines.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i);
            setSelectedTypes(allAvailableTypes);
            localStorage.removeItem("completedLines");
            options = props.lines.filter(obj => obj.id);
        }

        const i = Math.floor(Math.random() * options.length);
        // console.log('random option number: ', i);
        const id = options[i].id
        
        return id;
    }

    const cleanText = (obj) => {
        const newObj = {};
        Object.keys(obj).forEach(key => {
            newObj[key] = obj[key].trim();
        });
        return newObj;
    }

    const createMultiAnswers = (line) => {
        
        const type = line['type'];
        const field = multiChoiceGuess[type];
        
        const maxChars = 75;
        let correctAnswer, multiChoiceAnswers;
        if (difficultyEasy) {
            correctAnswer = line.en.length > maxChars ? line.en.slice(0,maxChars) + '...' : line.en;
            const filteredLines = props.lines.filter(obj => obj.type === type && obj.en !== line.en).map(obj => obj.en.length > maxChars ? obj.en.slice(0,maxChars) + '...' : obj.en);
            multiChoiceAnswers = getRandomSubarray(filteredLines, 3);
            multiChoiceAnswers.push(correctAnswer)
            
            
        } else {
            const filteredLines = props.lines.filter(obj => obj.type === type).map(obj => obj[field]);
            correctAnswer = line[field]
            multiChoiceAnswers = [correctAnswer];
            
            // console.log('filteredLines: ', filteredLines);
    
            const matches = stringSimilarity.findBestMatch(line[field], filteredLines).ratings;
            const topMatches = matches.sort((a,b) => b.rating - a.rating).slice(0,30).filter(obj => obj.target !== correctAnswer).map(obj => obj.target);
            const uniqueTopMatches = topMatches.filter((v, i, a) => a.indexOf(v) === i);
            for (let i=0; i<3; i++) {
                multiChoiceAnswers.push(uniqueTopMatches[i])
            }

        }
        const shuffledAnswers = shuffle(multiChoiceAnswers);
        // console.log('multiChoiceAnswers: ', shuffledAnswers);
        // console.log('topMatches: ', uniqueTopMatches);  
        setMultiAnswers(shuffledAnswers);
        setCorrectMulti(correctAnswer);
        
    }


    const pickQuote = () => {

        setLoading(true);

        // close steps on first "pickQuote"
        // if (props.sessionCount === 0) {
        //     const newShowSteps = {...showSteps};
        //     Object.keys(newShowSteps).forEach(key => {
        //         newShowSteps[key] = false;
        //     });
        //     setShowSteps(newShowSteps);
        // }

        // once a quote has been asked for, set pristine to false
        setPristine(false);



        // if button pressed before data loaded, do nothing
        if (!props.info || !props.lines) {
            // alert("I haven't fetched my data yet, give me a chance!");
            setModalMessage("I haven't fetched my data yet, give me a second or try refreshing the page");
            return false;
        }

        // if new quote asked for without having props.won previous round, reset streak
        if (!props.won) {
            props.resetStreak();
        }

        // check if user has had this quote before
        const prevType = previousType;
        const newId = getNewLineId(prevType);

        // run final clean up of data to remove trailing/leading white spaces that have cropped up in CSV
        const newSelectedLine = cleanText(props.lines.filter(obj => obj.id === newId)[0]);
        // console.log('newId: ', newId);

        // record that user has seen this line now
        const completedLines = JSON.parse(localStorage.getItem('completedLines')) ?? [];
        // console.log('completedLines: ', completedLines);
        const newCompletedLines = [...completedLines, newId];
        localStorage.setItem('completedLines', JSON.stringify(newCompletedLines));

        setSelectedLine(newSelectedLine);
        setMultiAnswers();
        setCorrectMulti();
        setIncorrectMulti();
        setTranslatedText();
        setShowTranslatedText(false);
        props.setWon(false);
        setPreviousType(newSelectedLine.type);

        // get meta data relating to line type (i.e. what fields we should know and what they relate to)
        const newLineInfo = props.info.filter(obj => obj.type === newSelectedLine.type && newSelectedLine[obj.info_field] !== '');
        setLineInfo(newLineInfo);        
        
        // create multi-choice answers
        createMultiAnswers(newSelectedLine);

        // const text = newSelectedLine[language];
        const text = newSelectedLine.en;

        if (text) {
            constructURL(text, language, !accentOnly);
        } else {
            alert('language not yet supported')
        }


        
        
        // const audioEl = document.getElementById('')
        // set input after a short delay to avoid enter shortcut for new quote triggering answer marking
        setTimeout(() => {
            setLoading(false);
            // scroll user to correct part of screen to see answers
            document.getElementById('quoteTypeTitle').scrollIntoView({ behavior: 'smooth'});        
        }, [350]); 
        
    }


    // function to take selected language, pick a random available voice, and set url for 
    const constructURL = useCallback((text, language, translateFlag) => {

        // console.log('language: ', language);
        const availableLetters = googleLanguageCodes[language].wavenets;
        const languageCode = googleLanguageCodes[language].textToSpeech;
        const seed = Math.floor(Math.random() * availableLetters.length);
        const name = languageCode + '-Wavenet-' + availableLetters[seed];
        const googleTranslateCode = googleLanguageCodes[language].translate;
        // console.log('[constructURL]: ', language, languageCode, name);
        console.log(name);

        // construct url to get speech for quote
        let newUrl = AWS_API + '/audio';
        newUrl += "?text=" + encodeURIComponent(text);
        newUrl += "&name=" + encodeURIComponent(name);
        newUrl += "&languageCode=" + encodeURIComponent(languageCode);
        newUrl += "&translate=" + encodeURIComponent(translateFlag);
        newUrl += "&translateCode=" + encodeURIComponent(googleTranslateCode);
        setUrl(newUrl);
    }, []);



    // logic to run anytime language or accentOnly setting changes during game
    useEffect(() => {
        // console.log('[language change useEffect outside IF]');
        if(language && selectedLine) {

            // const text = selectedLine[language];
            const text = selectedLine.en;

            // if langauge changes, reset translated text as it will need to be translated in new language
            if (language !== languageHistory) {
                setTranslatedText();
                setShowTranslatedText(false);
            }

            // only run if language has changed or accentOnlyHistory has changed
            if (language !== languageHistory || accentOnly !== accentOnlyHistory) {
    
                // console.log('[language change useEffect inside IF]');
                constructURL(text, language, !accentOnly)

                // add preferred settings to localstorage to use next time user visits
                localStorage.setItem("settings", JSON.stringify({'language': language, 'accentOnly': accentOnly}));
                
                // set languageHistory for check next time to only run this if language has changed
                setLanguageHistory(language);
                setAccentOnlyHistory(accentOnly);

            }
        }
    }, [language, selectedLine, props.won, constructURL, languageHistory, accentOnly, accentOnlyHistory]);

    const handleIncorrectMulti = (answer) => {

        // only change these if game isn't over
        if (!props.won) {

            // increment session count each time question answered (correctly or incorrectly)
            props.setSessionCount(props.sessionCount + 1);            

            setIncorrectMulti(answer);
            props.resetStreak();
    
            setTimeout(() => {
                // setIncorrectMulti();
                props.setWon(true);
            }, [10])

        }
    }

    // -----------------------------------------------------------------
    // handle playback speed changing for audio tag
    const audio = document.querySelector('.scc  audio');
    const playbackrate = document.querySelector('.scc input');
    const display = document.querySelector('.scc span');
    const displayvalue = val => {
        return parseInt(val * 100, 10) + '%'
    }
    
    if (audio && playbackrate && display) {
        if (window.localStorage.pbspeed) {
            audio.playbackRate = window.localStorage.pbspeed;
            playbackrate.value = window.localStorage.pbspeed;
        }
        display.innerText = displayvalue(audio.playbackRate);
        playbackrate.addEventListener('change', e => {
            audio.playbackRate = playbackrate.value;
            display.innerText = displayvalue(playbackrate.value);
            window.localStorage.pbspeed = playbackrate.value;
        });
    }
    // -----------------------------------------------------------------

    const getTranslatedText = async () => {
        
        setLoading2(true);
        setShowTranslatedText(!showTranslatedText);

        if (!translatedText) {
            
            const params = {
                text: selectedLine.en,
                translateCode: googleLanguageCodes[languageHistory].translate
            };
            try {
                const res = await axios.get(AWS_API + 'test-function', {params: params});
                console.log('res.data: ', res.data);
                setTranslatedText(res.data.translation);
            } catch (err) {
                console.log('err: ', err);
            }
        }

        setLoading2(false);

    }    



    

    
    const handleTypeSelect = (type) => {
        if (selectedTypes.includes(type)) {
            const newSelectedTypes = [...selectedTypes]
            newSelectedTypes.splice(newSelectedTypes.indexOf(type), 1);
            setSelectedTypes(newSelectedTypes);
            // console.log('selectedtypes: ', newSelectedTypes);
        } else {
            const newSelectedTypes = [...selectedTypes, type];
            setSelectedTypes(newSelectedTypes);
            // console.log('selectedtypes: ', newSelectedTypes);
        }
    }
    

    let selectTypesForm = <SpinnerDark />;
    if (props.lines && selectedTypes) {

        const buttons =  props.lines.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i).map(type => {
            
            let classList = [classes.LineTypeFormButton];
            if (selectedTypes.includes(type)) {classList = [classes.LineTypeFormButton, classes.SelectedLineTypeButton]}
            
            return(
                <div key={type} onClick={() => handleTypeSelect(type)} className={classList.join(' ')}>{type}</div>
                );
            })
            selectTypesForm = (
                    <div className={classes.LineTypeForm}>
                        {buttons}
                    </div>
        );
    }

    // let header = <h5 id="quoteTypeTitle" style={{color: 'darkgrey'}}>Click <button className="btn btn-xs" onClick={pickQuote}>New Quote</button> to begin ↓</h5>;
    let header;
    if (selectedLine) {
        header = (
            <Aux>
                <h5 id="quoteTypeTitle" className={classes.Title}>Quote Type: {selectedLine.type}</h5>
            </Aux>    
        );
    }


    let multiUI;
    if (multiAnswers) {
  

        multiUI = multiAnswers.map(answer => {

            // let classList = ["btn btn-outline-secondary"];
            // let classList = [classes.Button, classes.MultiChoiceAnswer];
            let classList = [];

            if (props.won && answer === correctMulti) {
                // classList.push(classes.CorrectMulti);
                classList = [classes.MultiChoiceAnswer, classes.CorrectMulti];
            } else if (answer === incorrectMulti) {
                // classList.push(classes.IncorrectMulti)
                classList = [classes.MultiChoiceAnswer, classes.IncorrectMulti];
            } else if (!props.won) {
                classList = [classes.MultiChoiceAnswer];
            } else {
                classList = [classes.MultiChoiceAnswer, classes.GameEnded];
            }

            // determine what should be attached to buttons onclick 
            let clickHandler = null;
            if (answer === correctMulti) {
                clickHandler = () => props.handleWin();
            } else if (!incorrectMulti) {
                clickHandler = () => handleIncorrectMulti(answer);
            }


            return (
                <div 
                key={answer} 
                className={classList.join(' ')} 
                // onClick={answer === correctMulti ? () => props.handleWin() : () => handleIncorrectMulti(answer)}>{answer}</div>
                onClick={clickHandler}>{answer}</div>
            );
        })
    }

        
    
    let answerButtons = <SpinnerDark />;
    if (!loading) {
        answerButtons = (
            <Aux>
                {header}
                {multiUI}
                {/* {originUI} */}
                {/* {transcribeUI} */}
            </Aux>
        );
    }

    let victoryContent = <span style={{color: 'darkgrey'}}>❝...❞</span>;
    if (selectedLine && lineInfo) {
        
        if (props.won && loading2) {
            victoryContent = <SpinnerDark />;
        } else if (props.won && !loading2) {
            const textToShow = showTranslatedText ? translatedText : selectedLine?.en
            victoryContent = (
                <div className={classes.VictoryContainer} onClick={getTranslatedText}>
                    <hr />
                    <span className={classes.ShowTextTranslated}>Click to toggle quote language</span>
                    <p style={{paddingTop: '10px'}}>❝ {textToShow} ❞</p>
                    {lineInfo.map(obj => {
                        return (
                            <p style={{fontSize: '.8rem', fontWeight: '600'}} key={obj.info_field}>{selectedLine[obj.info_field]}</p>
                            );
                        })}
                    <hr />
                </div>
            );
        }
    }
    
    const victoryContainer = <div className={classes.VictoryContainer} onClick={getTranslatedText}>{victoryContent}</div>;
    // let selectTypesSymbol = '&copy;';
    // if (showTypeSelection) {selectTypesSymbol = '⯆'}

    return (
        <Aux>
            <Modal show={modalMessage} modalClosed={() => setModalMessage()}>
                <h5>Oops, something isn't right...</h5>
                <hr/>
                <p>{modalMessage}</p>
            </Modal>             
            <Modal show={showPromptUserModal} modalClosed={() => setShowPromptUserModal(false)}>
                {/* <h5>Hi there!</h5> */}
                <hr/>
                <Socials />
                <hr/>
                <h6>Or let us know your ideas for improvement <Link to='about'>here</Link> </h6>
            </Modal>   
            <div className={classes.InnerContainer}>
                <TopDrawer title="Step One: Choose Language" open={showSteps[1]} toggle={() => setShowSteps({...showSteps, 1: !showSteps[1]})}>
                    <div style={{width: '100%'}}>
                        <ReactFlagsSelect 
                            countries={Object.keys(languageLabels)}
                            customLabels={languageLabels}
                            selected={language.toUpperCase()}
                            onSelect={(e) => setLanguage(e.toLowerCase())}
                            showSelectedLabel={true}
                            selectedSize={20}
                            optionsSize={20}
                            alignOptionsToRight
                            />

                    </div>
                    {/* <div className={classes.TitleLabel}>Step One: Choose Language</div> */}
                    {/* <hr /> */}
                </TopDrawer>
                <TopDrawer title="Step Two: Customise Categories" open={showSteps[2]} toggle={() => setShowSteps({...showSteps, 2: !showSteps[2]})}>
                    {/* <div className={classes.TitleLabel}>Step Two: Customise Categories</div> */}
                    <div className={classes.SelectTypesContainer}>
                        {/* <button onClick={() => setShowTypeSelection(!showTypeSelection)} className={[classes.SelectTypesButton, "btn btn-outline-dark"].join(' ')}>
                            {showTypeSelection ? <span>&#x25BC; </span> : null} 
                            {!showTypeSelection ? <span>&#x25B6; </span> : null} 
                            Customise Categories
                        </button> */}
                    </div>
                    {selectTypesForm}

                </TopDrawer>
                        
                <TopDrawer title="Step Three: Choose Audio Mode" open={showSteps[3]} toggle={() => setShowSteps({...showSteps, 3: !showSteps[3]})}>
                    {/* <div className={classes.TitleLabel}>Step Three: Choose Audio Mode</div> */}
                    <div className={classes.AccentOnlyContainer}>
                        <span className={classes.AccentToggleLabels}><b>Accented English</b></span>
                        <div className={classes.AccentToggleLabels}>
                            <Toggle checked={!accentOnly}  onChange={(e) => {setAccentOnly(!e.target.checked)}} />
                        </div>
                        <span className={classes.AccentToggleLabels}><b>{languageLabels[language.toUpperCase()]}</b></span>
                    </div>
                    {/* <hr /> */}
                </TopDrawer>
                <TopDrawer title="Step Four: Choose Difficulty" open={showSteps[4]} toggle={() => setShowSteps({...showSteps, 4: !showSteps[4]})}>
                    {/* <div className={classes.TitleLabel}>Step Three: Choose Audio Mode</div> */}
                    <div className={classes.AccentOnlyContainer}>
                        <span className={classes.AccentToggleLabels}><b>Easy</b></span>
                        <div className={classes.AccentToggleLabels}>
                            <Toggle checked={!difficultyEasy}  onChange={(e) => {setDifficultyEasy(!e.target.checked)}} />
                        </div>
                        <span className={classes.AccentToggleLabels}><b>Hard</b></span>
                    </div>
                    {/* <hr /> */}
                </TopDrawer>
                <div className={classes.TitleLabel}><span>&#x25BC; </span> Step Five: Guess The Quote!</div>
                {pristine && <button className={[classes.Button, 'btn'].join(' ')} onClick={pickQuote}>New Quote</button>}
                
                {answerButtons}
                {victoryContainer}
                <Socials />
            </div>      
            {!pristine && <div className={classes.Footer} >
                <div className={classes.FooterWidthControl}>
                    <Streak currentStreak={props.currentStreak} maxStreak={props.maxStreak} />
                    <div className={classes.FooterButtons}>
                        {/* {selectedLine && !props.won ? <button className={[classes.Button, classes.ButtonSecondary].join(' ')} onClick={() => props.setWon(true)}>Give Up</button> : null} */}
                        {/* {selectedLine && !props.won ? <p>&nbsp;</p> : null} */}
                        <button className={[classes.Button, 'btn'].join(' ')} onClick={pickQuote}>New Quote</button>
                    </div>
                    <p> </p>
                    {/* <audio src={url} id="audioElement" controls autoPlay> Your browser does not support the audio tag.</audio>  */}
                    {url && 
                    <div className={["scc", classes.SCC].join(' ')}>
                    <audio src={url} controls autoPlay></audio>
                    <div>
                        <label >Speed:</label>
                        <input type="range" id="pbrate" min={.5} max={2}  step={.1} />
                        <span></span>
                    </div>
                    </div>
                    }
                </div>
            </div>}
        </Aux>
    );
}

export default Quotes;