// import Aux from '../../hoc/Auxiliary/Auxiliary';
// import { useState, useEffect } from 'react';
import { Fragment, useState } from 'react';
import classes from './DailyStreak.module.css';
import Modal from '../../../UI/Modal/Modal';
import StreakSummary from './StreakSummary/StreakSummary';

const DailyStreak = (props) => {

    const [showSummary, setShowSummary] = useState(false);
    console.log('[DailyStreak.js] - daysInStreak: ', props.daysInStreak);

    return (
        <Fragment>
            <div onClick={() => setShowSummary(true)} className={[classes.outerRing, classes.Click].join(' ')}>
                <div onClick={() => setShowSummary(true)}  className={[classes.innerRing, classes.Click].join(' ')}>
                </div>

            </div>
            <div onClick={() => setShowSummary(true)} className={[classes.Streak, classes.Click].join(' ')}>
                {props.daysInStreak}d
            
            </div>
            <Modal show={showSummary} modalClosed={() => setShowSummary(false)}>
                <StreakSummary />
            </Modal>  

        </Fragment>

    );
}

export default DailyStreak;