import {
    // FacebookShareButton, 
    // FacebookIcon,
    // FacebookMessengerShareButton, FacebookMessengerIcon,
    // TwitterShareButton, TwitterIcon,
    // RedditShareButton, RedditIcon,
    // WhatsappShareButton, WhatsappIcon

  } from "react-share";
import { 
    // shareUrl, 
    instaUrl, 
    // fbUrl 
} from "../../config/config";
import classes from './Socials.module.css';

// import { SocialIcon } from 'react-social-icons';
import InstaIcon from '../../assets/images/instaIcon.png'

  
  

const Socials = () => {
    return (
        <div className={classes.Socials}>
            <div className={classes.Title}>Enjoying the game?</div>
            <div className={classes.SocialsRow}>
                <div className={classes.SocialItem}>
                    {/* <SocialIcon url="https://www.instagram.com/quotey.mcquoteface/" />       */}
                    <a href={instaUrl}>
                        <img alt="insta-logo" src={InstaIcon} style={{width: '40px'}} />      
                    </a>
                    <div className={classes.Text}>Follow Quotey on Instagram for daily quizes</div>
                </div>
                <div className={classes.Or}> </div>
                <div className={classes.SocialItem}>
                    <div className={classes.Text}> Buy Quotey a coffee</div> 
                    <a href="https://www.buymeacoffee.com/quotey" className={classes.CoffeeIcon}>&#x2615;</a>
                </div>

            </div>
            {/* <div className={classes.SocialItem}>
                <a href={fbUrl}>
                    <FacebookIcon round size={36} />
                </a>
            </div> */}
            {/* <div className={classes.SocialItem}>
                <TwitterShareButton url={shareUrl}>
                    <TwitterIcon round size={32} />
                </TwitterShareButton>            
            </div>
            <div className={classes.SocialItem}>
                <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon round size={32} />
                </WhatsappShareButton>            
            </div>
            <div className={classes.SocialItem}>
                <RedditShareButton url={shareUrl}>
                    <RedditIcon round size={32} />
                </RedditShareButton>            
            </div> */}
            {/* <div className={classes.SocialItem}>
                <FacebookMessengerShareButton url={shareUrl}>
                    <FacebookMessengerIcon round size={32} />
                </FacebookMessengerShareButton>            
            </div> */}
        </div>

    );
}

export default Socials;