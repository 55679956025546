import React from 'react';
import { NavLink } from 'react-router-dom';
import Mascot from '../../assets/images/mascot.png';
import classes from '../Logo/Logo.module.css';

const logo = (props) => (
    // <NavLink to="/">
        <div className={classes.Logo}>
                {/* <h1 className={classes.Title}>Quotey</h1>   */}
                <a href="/">
                    <img src={Mascot} alt="ao-logo"/>
                </a>
                <p>&nbsp;</p>
                <NavLink to="/" className={classes.Title}>Quotey</NavLink>  
        </div>
            
    // </NavLink>
);

export default logo;