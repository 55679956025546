export const AWS_API = 'https://9g5lny6wl1.execute-api.ap-southeast-2.amazonaws.com/';

export const shareUrl = 'https://www.quotey-mcquoteface.com';
export const instaUrl = 'https://www.instagram.com/quotey.mcquoteface';
export const fbUrl = 'https://www.facebook.com/quotey.mcquote';

export const multiChoiceGuess = {
    'song': 'info2',
    'movie': 'info1',
    'tv series': 'info1',
    'quote': 'info1',
    'book': 'info1'
};

export const googleLanguageCodes = {
    'it': {'translate': 'it', 'textToSpeech': 'it-IT', 'wavenets': ['A', 'B', 'C', 'D']},
    'es': {'translate': 'es', 'textToSpeech': 'es-ES', 'wavenets': [ 'B', 'C', 'D']},
    'fr': {'translate': 'fr', 'textToSpeech': 'fr-FR', 'wavenets': ['A', 'B', 'C', 'D', 'E']},
    'pt': {'translate': 'pt', 'textToSpeech': 'pt-PT', 'wavenets': ['A', 'B', 'C', 'D']},
    'gr': {'translate': 'el', 'textToSpeech': 'el-GR', 'wavenets': ['A']},
    'jp': {'translate': 'ja', 'textToSpeech': 'ja-JP', 'wavenets': ['A', 'B', 'C', 'D']},
    'de': {'translate': 'de', 'textToSpeech': 'de-DE', 'wavenets': ['A', 'B', 'C', 'D', 'E', 'F']},
    'kr': {'translate': 'ko', 'textToSpeech': 'ko-KR', 'wavenets': ['A', 'B', 'C', 'D']},
    'ru': {'translate': 'ru', 'textToSpeech': 'ru-RU', 'wavenets': ['A', 'B', 'C', 'D', 'E']},
    'gb': {'translate': 'en', 'textToSpeech': 'en-GB', 'wavenets': ['A', 'B', 'C', 'D', 'F']},
    'cn': {'translate': 'zh-TW', 'textToSpeech': 'cmn-TW', 'wavenets': ['A', 'B', 'C']},
}

export const languageLabels = { 
    "CN": "Chinese",
    "DE": "German", 
    "ES": "Spanish", 
    "FR": "French", 
    "GB" :"English",
    "GR": "Greek", 
    "IT": "Italian",
    "JP": "Japanese", 
    "KR": "Korean", 
    "PT": "Portuguese", 
    "RU": "Russian",
    }
    

// export const lanugageCodes = {
//     'it': 'it-IT',
//     'es': 'es-ES',
//     'fr': 'fr-FR',
//     'pt': 'pt-PT',
//     'gr': 'el-GR',
//     'jp': 'ja-JP',
//     'de': 'de-DE',
//     'kr': 'ko-KR',
//     'ru': 'ru-RU',
//     'gb': 'en-GB',
//     'cn': 'cmn-CN'

// };

// export const wavenets = {
//     'it': ['A', 'B', 'C', 'D'],
//     'es': [ 'B', 'C', 'D'],
//     'fr': ['A', 'B', 'C', 'D', 'E'],
//     'pt': ['A', 'B', 'C', 'D'],
//     'gr': ['A'],
//     'jp': ['A', 'B', 'C', 'D'],
//     'de': ['A', 'B', 'C', 'D', 'E', 'F'],
//     'kr': ['A', 'B', 'C', 'D'],
//     'ru': ['A', 'B', 'C', 'D', 'E'],
//     'gb': ['A', 'B', 'C', 'D', 'F'],
// };



