export const arraysMatch = function (arr1, arr2) {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

export const shuffle = (array) =>  {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  export const cleanText = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key] = obj[key].trim();
    });
    return newObj;
}

export const getNewLineId = (lines, localCompletedLines) => {
    // console.log('lines: ', lines);
    
    const completedLines = JSON.parse(localStorage.getItem(localCompletedLines)) ?? [];
    // console.log('completedLines, numLines, prevType: ', completedLines, lines.length, prevType);

    // get options for quotes which haven't been completed yet
    let options = lines.filter(obj => obj.id && !completedLines.includes(obj.id))
    // console.log('[helpers.js]- options: ', options);
    if (options && options.length > 0) {
        console.log(lines.length, options.length, options.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i));
    }

    // options length 0 here means that user has completed all lines - reset local storage
    if (options.length === 0) {
        // console.log('no options of any type left, clearing local storage and starting again');
        // alert('All quotes of selected types have been played, resetting selection');
        alert('All quotes have been played in this game mode, resetting selection');
        localStorage.removeItem(localCompletedLines);
        options = lines.filter(obj => obj.id);
    }

    const i = Math.floor(Math.random() * options.length);
    // console.log('random option number: ', i);
    const id = options[i].id

    // record that user has seen this line now
    const newCompletedLines = [...completedLines, id];
    localStorage.setItem(localCompletedLines, JSON.stringify(newCompletedLines));
    
    return id;
}


export const getRandomSubarray = (arr, size) => {
    var shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
}


export const calculateDailyStreak = (setDaysFunction, dailyStreak) => {
    // recursive function to check next date
    const checkNextDate = (date, daysInStreak) => {
        const dateString = date.toLocaleDateString('sv-SW');
        // console.log('dateString in recursive function: ', dateString);
        // console.log('dailyStreak in recursive function: ', dailyStreak);
        if (dailyStreak.includes(dateString)) {
            daysInStreak += 1;
            // console.log('incrementing daysInStreak: ', daysInStreak);

            // increment date by one and check next date
            const dayBefore = new Date(date);
            dayBefore.setDate(dayBefore.getDate() - 1);
            daysInStreak = checkNextDate(dayBefore, daysInStreak);
        } else {
            return daysInStreak;
        }

        return daysInStreak;
    }

    const today = new Date();
    let newDaysInStreak = checkNextDate(today, 0);

    // if streak is 0, check if there was actually a streak up until yesterday and today's play is just pending
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (newDaysInStreak === 0) {
        newDaysInStreak = checkNextDate(yesterday, 0);
        // console.log('new Days in Steak counted up until yesterday: ', newDaysInStreak);
    }
    // console.log('newDaysInStreak: ', newDaysInStreak);

    // update days in streak in App.js
    setDaysFunction(newDaysInStreak);
}

