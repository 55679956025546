import {React, useState} from "react";
import classes from './Layout.module.css';
import Toolbar from "../../UI/Navigation/Toolbar/Toolbar";
import SideDrawer from "../../UI/Navigation/SideDrawer/SideDrawer";


export default function Layout(props) {

  const [showSideDrawer, setShowSideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => {
    setShowSideDrawer(false);
  }

  const sideDrawerToggleHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  }

  return (
    <div className={classes.OuterContainer}>
      <Toolbar 
        toggleOrigin={props.toggleOrigin} setToggleOrigin={props.setToggleOrigin}
        toggleTranscribe={props.toggleTranscribe} setToggleTranscribe={props.setToggleTranscribe}
        toggleMulti={props.toggleMulti} setToggleMulti={props.setToggleMulti}
        drawerToggleClicked={sideDrawerToggleHandler}
        daysInStreak={props.daysInStreak}

      />
      <SideDrawer 
        closed={sideDrawerClosedHandler} 
        open={showSideDrawer}
        toggleOrigin={props.toggleOrigin} setToggleOrigin={props.setToggleOrigin}
        toggleTranscribe={props.toggleTranscribe} setToggleTranscribe={props.setToggleTranscribe}  
        toggleMulti={props.toggleMulti} setToggleMulti={props.setToggleMulti}      
        showInstallPrompt={props.showInstallPrompt}
        showInstallInstruct={props.showInstallInstruct}
        accepted={props.accepted}
        dismissed={props.dismissed}         
        />
      <main className={classes.Body}>
        {props.children}
      </main>
    </div>
  )
}