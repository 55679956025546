// base64 encoding mp3 as audio src: https://www.specialagentsqueaky.com/blog-post/mjsm6q9d/2020-11-26-how-to-create-your-own-text-to-speech-using-amazon-polly/#step-2-create-our-basic-lambda-function
// example request format: https://cloud.google.com/text-to-speech

import './App.css';
import {useState, useRef, useEffect } from 'react';
import { Routes, Route} from "react-router-dom";
import Layout from './hoc/Layout/Layout';
// import Main from './components/Main/Main';
import About from './components/About/About';
import axios from 'axios';
import { AWS_API } from './config/config';
import Modes from './components/Modes/Modes';

function App() {
    // state
    const [toggleTranscribe, setToggleTranscribe] = useState(false);
    const [toggleOrigin, setToggleOrigin] = useState(false);
    const [toggleMulti, setToggleMulti] = useState(true);
    // PWA state
    const [showInstallPrompt, setShowInstallPrompt] = useState();
    const [showInstallInstruct, setShowInstallInstruct] = useState();

    // state from Main.js
    const [daysInStreak, setDaysInStreak] = useState(0);
    console.log('[App.js] - daysInStreak: ', daysInStreak);



    // ---------------
    // TESTING GOOGLE ADSENSE SCRIPT STUFF
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2043405296933872";
        script.async = true;
      
        document.head.appendChild(script);
      
        return () => {
          document.head.removeChild(script);
        }
      }, []);    

    //---------------------------------------------------------------------------------------------------------------------------------
    // PWA SETUP
    //---------------------------------------------------------------------------------------------------------------------------------

    let installPrompt = useRef();

    // ensure that multi-choice is never on at the same time as other game modes, but is on if no other game mode is selected
    useEffect(() => {
        if (toggleMulti) {
            setToggleOrigin(false);
            setToggleTranscribe(false);
            
        } else if (toggleOrigin || toggleTranscribe) {
            setToggleMulti(false);
        }
    }, [toggleTranscribe, toggleOrigin, toggleMulti])

    useEffect(() => {
        console.log("Listening for Install prompt");
        window.addEventListener('beforeinstallprompt',e => {
            // For older browsers
            e.preventDefault();
            console.log("Install Prompt fired");
            installPrompt.current = e;
            // See if the app is already installed, in that case, do nothing
            if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
            return false;
            }
            // Set the state variable to make button visible
            setShowInstallPrompt(true);
        });

        // Checks if should display install instructions in sidebar
        if (isIos() && !isInStandaloneMode()) {
            setShowInstallInstruct(true);
        }

    }, []);


    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);


    const installApp = async () => {
        if(!installPrompt.current) return false;
            installPrompt.current.prompt();
            let outcome = await installPrompt.current.userChoice;
        if(outcome.outcome === 'accepted'){
            console.log("App Installed");
            
            // send analytics event
            const params = {
                analyticsEvent: 'app download'
            };            
            try {
                const res = await axios.get(AWS_API + 'user-downloaded-app', {params: params});
                console.log('Analytics: ', res.data);
            } catch (err) {
                console.log('error: ', err);
            }    
        }
        else{
            console.log("App not installed");
        }
        // Remove the event reference
        installPrompt.current = null;
        // Hide the button
        setShowInstallPrompt(false);
    }


    //---------------------------------------------------------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------------------------------------------------------

    // send analytics event on each site visit to log type of device visited from
    useEffect(() => {
        const sendAnalyticsEvent = async () => {
            // send analytics event
            const params = {
                analyticsEvent: 'site visit',
                isIos: isIos() ? 1 : 0,
                isInStandaloneMode: isInStandaloneMode() ? 1 : 0 
            };
            try {
                const res = await axios.get(AWS_API + 'user-downloaded-app', {params: params});
                console.log('Analytics: ', res.data);
            } catch (err) {
                console.log('error: ', err);
            }            
        }

        sendAnalyticsEvent();
    }, []);

  
  return (
    <div className="App">
        <Layout 
            toggleOrigin={toggleOrigin} 
            setToggleOrigin={setToggleOrigin} 
            toggleTranscribe={toggleTranscribe} 
            setToggleTranscribe={setToggleTranscribe} 
            toggleMulti={toggleMulti} 
            setToggleMulti={setToggleMulti}
            daysInStreak={daysInStreak}
            
            showInstallPrompt={showInstallPrompt} 
            showInstallInstruct={showInstallInstruct}
            accepted={installApp} 
            dismissed={() => setShowInstallPrompt(false)}        
        >
            <Routes>
                {/* <Route path="/old" element={<Main toggleOrigin={toggleOrigin} toggleTranscribe={toggleTranscribe} toggleMulti={toggleMulti} setDaysInStreak={setDaysInStreak}/>} /> */}
                <Route path="/" element={<Modes setDaysInStreak={setDaysInStreak} />} />
                <Route path="/about" element={<About />} />
                <Route path="*" element={<Modes />} />
            </Routes>
        </Layout>
    </div>
  );
}

export default App;










// import {words, wordsJson} from './words.js';
// import axios from 'axios';


// const stringSimilarity = require("string-similarity");

// const [bestMatch, setBestMatch] = useState();

// find word most similar to user input
// const findUserMatch = (e) => {
//   const charCode = (typeof e.which == "number") ? e.which : e.keyCode;
//   if (charCode === 13) {    
//     // const userInputMatch = stringSimilarity.findBestMatch(userInput, words);
//     // const userInputMatch = stringSimilarity.findBestMatch(userInput, wordsJson.map(obj => obj.it));
//     // console.log('userInputMatch: ', userInputMatch);
//     setBestMatch(userInputMatch.bestMatch)
    
//   }   
// }



// const test = async () => {
  
  //   const config = {
    //       params: {text: 'hi'}
    //   };
    //   try {
      //       const res = await axios.get(AWS_API + 'audio', config);
      //       console.log('res: ', res);
//       setAudio(res.data);

      
//     } catch (err) {
//       console.log('err: ', err);
//     }  
//   }
// test();

// const results = {};
// for (const char of chars) {
  //   results[char] = [];
  
  // const matches = stringSimilarity.findBestMatch(char, words);
  // console.log('character: ', char);
  // console.log('matches: ', matches);
  
  
  //   for (const word of words) {
    //     const sim = stringSimilarity.compareTwoStrings(char, word);
    //     const res = {
      //       word: word,
      //       similarity: sim
      //     };
      //     results[char].push(res);
      //   }
      // }
      
      // const similarities = [];
      
      // Object.keys(results).forEach(char => {
        //   const sims = results[char];
        //   const sortedSims = sims.sort((a,b) => b.similarity - a.similarity);
        //   // console.log(sortedSims);
        //   const mostSimilar = sortedSims[0].word;
        //   similarities.push({character: char, word: mostSimilar, similarity: sortedSims[0].similarity})
        //   // console.log('mostSimilar: ', mostSimilar);
        // });
        
        // console.log('similarities: ', similarities.sort((a,b) => b.similarity - a.similarity));