// import Aux from '../../hoc/Auxiliary/Auxiliary';
import { useState, 
    // useEffect, 
    useCallback } from 'react';
import classes from './Accents.module.css';
import QuotesClasses from '../Quotes/Quotes.module.css';
import Streak from '../../Streak/Streak';
import {cleanText, getNewLineId, getRandomSubarray, shuffle} from '../../../helpers/helpers';
import { googleLanguageCodes, languageLabels, AWS_API } from '../../../config/config';
import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import axios from 'axios';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import Socials from '../../../hoc/Socials/Socials';


const Accents = (props) => {
    
    const [pristine, setPristine] = useState(true);
    const [url, setUrl] = useState();
    const [loading, setLoading] = useState();
    const [loadingTranslatedText, setLoadingTranslatedText] = useState();
    
    const [selectedLine, setSelectedLine] = useState();
    const [lineInfo, setLineInfo] = useState();    
    
    const [multiAnswers, setMultiAnswers] = useState();
    const [correctMulti, setCorrectMulti] = useState();
    const [incorrectMulti, setIncorrectMulti] = useState();    
    
    const [translatedText, setTranslatedText] = useState();
    const [showTranslatedText, setShowTranslatedText] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState();
    
    // determine game mode from props
    const mode = props.mode;

    const pickQuote = () => {

        setLoading(true);

        // once a quote has been asked for, set pristine to false
        setPristine(false);

        // if button pressed before data loaded, do nothing
        if (!props.info || !props.lines) {
            // alert("I haven't fetched my data yet, give me a chance!");
            alert("I haven't fetched my data yet, give me a second or try refreshing the page");
            return false;
        }


        // if new quote asked for without having props.won previous round, reset streak
        if (!props.won) {
            props.resetStreak();
        }

        // check if user has had this quote before
        const newId = getNewLineId(props.lines, 'accent-completed-lines');
        // console.log('newId returned from function: ', newId);

        // run final clean up of data to remove trailing/leading white spaces that have cropped up in CSV
        const newSelectedLine = cleanText(props.lines.filter(obj => obj.id === newId)[0]);
        setSelectedLine(newSelectedLine);
        // console.log('newId: ', newId);

        // get meta data relating to line type (i.e. what fields we should know and what they relate to)
        const newLineInfo = props.info.filter(obj => obj.type === newSelectedLine.type && newSelectedLine[obj.info_field] !== '');
        setLineInfo(newLineInfo);                

        const text = newSelectedLine.en;
        // console.log('selected text: ', text);

        // pick random language
        const availableLanguages = Object.keys(googleLanguageCodes).filter(key => key !== 'gb');
        const languageSeed = Math.floor(Math.random() * availableLanguages.length);
        // console.log('available languages: ', availableLanguages);
        // console.log('languageseed: ', languageSeed);
        const language = availableLanguages[languageSeed];  
        // console.log('selected langauge: ', language);     
        setSelectedLanguage(language); 

        // reset from last game
        setMultiAnswers();
        setCorrectMulti();
        setIncorrectMulti();
        setTranslatedText();
        setShowTranslatedText(false);
        props.setWon(false);        


        // create multi-choice answers
        createMultiAnswers(language);        
        
        // construct URL based on game mode
        let translateFlag;
        if (mode === 'guessAccent') {
            translateFlag = false;
        } else if (mode === 'guessLanguage') {
            translateFlag = true;
        } else {
            alert('game mode not supported');
        }
        constructURL(text, language, translateFlag);



        
        // const audioEl = document.getElementById('')
        // set input after a short delay to avoid enter shortcut for new quote triggering answer marking
        setTimeout(() => {
            setLoading(false);
            // scroll user to correct part of screen to see answers
            document.getElementById('quoteTypeTitle').scrollIntoView({ behavior: 'smooth'});        
        }, [350]); 
        
    }

    // function to take selected language, pick a random available voice, and set url for 
    const constructURL = useCallback((text, language, translateFlag) => {

        // pick random language
        // console.log('googleLanguageCodes: ', googleLanguageCodes);

        // console.log('language: ', language);

        const availableLetters = googleLanguageCodes[language].wavenets;
        const languageCode = googleLanguageCodes[language].textToSpeech;
        const seed = Math.floor(Math.random() * availableLetters.length);
        const name = languageCode + '-Wavenet-' + availableLetters[seed];
        const googleTranslateCode = googleLanguageCodes[language].translate;
        console.log('[constructURL]: language, laguageCode, name ', language, languageCode, name);

        // construct url to get speech for quote
        let newUrl = AWS_API + '/audio';
        newUrl += "?text=" + encodeURIComponent(text);
        newUrl += "&name=" + encodeURIComponent(name);
        newUrl += "&languageCode=" + encodeURIComponent(languageCode);
        newUrl += "&translate=" + encodeURIComponent(translateFlag);
        newUrl += "&translateCode=" + encodeURIComponent(googleTranslateCode);        
        setUrl(newUrl);
    }, []);    


    const createMultiAnswers = (language) => {

        const languageUpper = language.toUpperCase();
        const correctAnswer = languageLabels[languageUpper]; 
        // console.log('correct answer: ', correctAnswer);


        const availableLanguages = Object.values(languageLabels).filter(el => el !== correctAnswer && el !== 'English');
        // console.log('available languages for other answers: ', availableLanguages);

        const multiChoiceAnswers = getRandomSubarray(availableLanguages, 3);
        multiChoiceAnswers.push(correctAnswer);
        const shuffledAnswers = shuffle(multiChoiceAnswers);
        // console.log('shuffledAnswers: ', shuffledAnswers);  
        setMultiAnswers(shuffledAnswers);
        setCorrectMulti(correctAnswer);
        
    }    

    const getTranslatedText = async () => {
        
        setLoadingTranslatedText(true);
        setShowTranslatedText(!showTranslatedText);

        if (!translatedText) {
            
            const params = {
                text: selectedLine.en,
                translateCode: googleLanguageCodes[selectedLanguage].translate
            };
            try {
                const res = await axios.get(AWS_API + 'test-function', {params: params});
                console.log('res.data: ', res.data);
                setTranslatedText(res.data.translation);
            } catch (err) {
                console.log('err: ', err);
            }
        }

        setLoadingTranslatedText(false);

    }    

    const handleIncorrectMulti = (answer) => {

        // only change these if game isn't over
        if (!props.won) {

            // increment session count each time question answered (correctly or incorrectly)
            props.setSessionCount(props.sessionCount + 1);            

            setIncorrectMulti(answer);
            props.resetStreak();
    
            setTimeout(() => {
                // setIncorrectMulti();
                props.setWon(true);
            }, [10])

        }
    }    

    // -----------------------------------------------------------------
    // handle playback speed changing for audio tag
    const audio = document.querySelector('.scc  audio');
    const playbackrate = document.querySelector('.scc input');
    const display = document.querySelector('.scc span');
    const displayvalue = val => {
        return parseInt(val * 100, 10) + '%'
    }
    
    if (audio && playbackrate && display) {
        if (window.localStorage.pbspeed) {
            audio.playbackRate = window.localStorage.pbspeed;
            playbackrate.value = window.localStorage.pbspeed;
        }
        display.innerText = displayvalue(audio.playbackRate);
        playbackrate.addEventListener('change', e => {
            audio.playbackRate = playbackrate.value;
            display.innerText = displayvalue(playbackrate.value);
            window.localStorage.pbspeed = playbackrate.value;
        });
    }
    // -----------------------------------------------------------------


    let header;
    if (selectedLine) {
        header = (
                <h5 id="quoteTypeTitle" className={QuotesClasses.Title}>Quote Type: {selectedLine.type}</h5>
        );
    }


    let multiUI;
    if (multiAnswers) {
  

        multiUI = multiAnswers.map(answer => {

            // let classList = ["btn btn-outline-secondary"];
            // let classList = [QuotesClasses.Button, QuotesClasses.MultiChoiceAnswer];
            let classList = [];

            if (props.won && answer === correctMulti) {
                // classList.push(QuotesClasses.CorrectMulti);
                classList = [QuotesClasses.MultiChoiceAnswer, QuotesClasses.CorrectMulti];
            } else if (answer === incorrectMulti) {
                // classList.push(QuotesClasses.IncorrectMulti)
                classList = [QuotesClasses.MultiChoiceAnswer, QuotesClasses.IncorrectMulti];
            } else if (!props.won) {
                classList = [QuotesClasses.MultiChoiceAnswer];
            } else {
                classList = [QuotesClasses.MultiChoiceAnswer, QuotesClasses.GameEnded];
            }

            // determine what should be attached to buttons onclick 
            let clickHandler = null;
            if (answer === correctMulti) {
                clickHandler = () => props.handleWin();
            } else if (!incorrectMulti) {
                clickHandler = () => handleIncorrectMulti(answer);
            }


            return (
                <div 
                key={answer} 
                className={classList.join(' ')} 
                // onClick={answer === correctMulti ? () => props.handleWin() : () => handleIncorrectMulti(answer)}>{answer}</div>
                onClick={clickHandler}>{answer}</div>
            );
        })
    }

        
    
    let answerButtons = <SpinnerDark />;
    if (!loading) {
        answerButtons = (
            <Aux>
                {header}
                {multiUI}
                {/* {originUI} */}
                {/* {transcribeUI} */}
            </Aux>
        );
    }

    let victoryContent;
    if (selectedLine && lineInfo) {

        if (props.won && loadingTranslatedText) {
            victoryContent = <SpinnerDark />;
        } else if (props.won && !loadingTranslatedText) {
            const textToShow = showTranslatedText ? translatedText : selectedLine?.en;
            victoryContent = (
                <div className={QuotesClasses.VictoryContainer} onClick={getTranslatedText}>
                    <hr />
                    <span className={QuotesClasses.ShowTextTranslated}>Click to toggle quote language</span>
                    <p style={{paddingTop: '10px'}}>❝ {textToShow} ❞</p>
                    {lineInfo.map(obj => {
                        return (
                            <p style={{fontSize: '.8rem', fontWeight: '600'}} key={obj.info_field}>{selectedLine[obj.info_field]}</p>
                            );
                        })}
                    <hr />
                </div>
            );
        } else if (!pristine) {
            victoryContent = <span style={{color: 'darkgrey'}}>❝...❞</span>;
        }
    }

    const victoryContainer = victoryContent ? <div className={QuotesClasses.VictoryContainer} onClick={getTranslatedText}>{victoryContent}</div> : <p> </p>;    

    
    // let banner;
    //     if (true) {
    //     // banner = <div className={showWonBanner ? [QuotesClasses.Banner, QuotesClasses.Visible].join(' ') :  [QuotesClasses.Banner, QuotesClasses.Hidden].join(' ') }>Nice!</div>
    //     banner = <div className={ [QuotesClasses.Banner, QuotesClasses.Visible].join(' ') }>Nice!</div>
    // }  
    


    return (
        <div className={classes.Accents}>
            <div className={classes.InnerContainer}>
                {pristine && <div className={QuotesClasses.TitleLabel}>Tap "New Quote" below to try and guess the {mode === 'guessAccent' ? 'accent you hear' : 'language you hear'}!</div>}
                {pristine && <button className={[QuotesClasses.Button, 'btn'].join(' ')} onClick={pickQuote}>New Quote</button>}

                {answerButtons}
                {victoryContainer}
                
                <Socials />
            </div>


            {!pristine && <div className={QuotesClasses.Footer} >
                <div className={QuotesClasses.FooterWidthControl}>
                    <Streak currentStreak={props.currentStreak} maxStreak={props.maxStreak} />
                    <div className={QuotesClasses.FooterButtons}>
                        {/* {selectedLine && !props.won ? <button className={[QuotesClasses.Button, QuotesClasses.ButtonSecondary].join(' ')} onClick={() => setWon(true)}>Give Up</button> : null} */}
                        {/* {selectedLine && !props.won ? <p>&nbsp;</p> : null} */}
                        <button className={[QuotesClasses.Button, 'btn'].join(' ')} onClick={pickQuote}>New Quote</button>
                    </div>
                    <p> </p>
                    {/* <audio src={url} id="audioElement" controls autoPlay> Your browser does not support the audio tag.</audio>  */}
                    {url && 
                    <div className={["scc", QuotesClasses.SCC].join(' ')}>
                    <audio src={url} controls autoPlay></audio>
                    <div>
                        <label >Speed:</label>
                        <input type="range" id="pbrate" min={.5} max={2}  step={.1} />
                        <span></span>
                    </div>
                    </div>
                    }
                </div>
            </div>}

        </div>


    );
}

export default Accents;