import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
// import Aux from '../../../hoc/Auxiliary/Auxiliary';

import "react-toggle/style.css";
// import Toggle from 'react-toggle';
// import Aux from '../../../hoc/Auxiliary/Auxiliary';

const navigationItems = (props) => {

    // if multi-choice is set, don't show the other options
    // let otherToggles = (
    //     <Aux>
    //         <p>&nbsp;&nbsp;</p>
    //         {/* <p>&nbsp;&nbsp;&nbsp;&nbsp;</p> */}

    //         <span className={classes.ToggleLabel}> Transcribe line&nbsp;</span>
    //         <Toggle checked={props.toggleTranscribe} onChange={(e) => {props.setToggleTranscribe(e.target.checked);}} />
    //         <p>&nbsp;&nbsp;</p>

    //         <span className={classes.ToggleLabel}>Name origin&nbsp;</span>
    //         <Toggle checked={props.toggleOrigin} onChange={(e) => {props.setToggleOrigin(e.target.checked);}} />
    //     </Aux>
    // ); 
    // if (props.toggleMulti) {
    //     otherToggles = null;
    // }

    return (
        <div >
            {/* <ul className={classes.NavigationItemsLeft}>

            </ul> */}
            <ul className={classes.NavigationItems}>
                    <NavigationItem link="/">Play</NavigationItem>   
                    {/* <NavigationItem link="/modes">New</NavigationItem>    */}
                    <NavigationItem link="/about">About</NavigationItem>   

                {/* {otherToggles}

                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                
                <span className={classes.ToggleLabel}> Multi-Choice&nbsp;</span>
                <Toggle checked={props.toggleMulti} onChange={(e) => props.setToggleMulti(e.target.checked)} />
                 */}
            </ul>
        </div>

    );

}

export default navigationItems;