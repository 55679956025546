import classes from './Modes.module.css';
import React, {
     useEffect,
    //  useRef, 
    // useCallback 
} from "react";
import { useState } from 'react';
// import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';
// import ReactFlagsSelect from 'react-flags-select';
// import { googleLanguageCodes, languageLabels } from '../../config/config';
import { 
    AWS_API, 
    // multiChoiceGuess 
} from '../../config/config';
import axios from 'axios';
import Socials from '../../hoc/Socials/Socials';
// import Coffee from '../../hoc/Coffee/Coffee';
// import Streak from '../Streak/Streak';
// import Toggle from 'react-toggle';
import Modal from '../../UI/Modal/Modal';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import Accents from './Accents/Accents';
import { calculateDailyStreak } from '../../helpers/helpers';
import Spinner from '../../UI/SpinnerDark/SpinnerDark';
// import Aux from '../../hoc/Auxiliary/Auxiliary';
import Quotes from './Quotes/Quotes';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';




// var stringSimilarity = require("string-similarity");


const Modes = (props) => {

    // state
    // const [selectedLine, setSelectedLine] = useState();
    // const [translatedText, setTranslatedText] = useState();
    // const [showTranslatedText, setShowTranslatedText] = useState(false);

    const [tab, setTab] = useState('quotes');


    // UI state
    const [modalMessage, setModalMessage] = useState();
    const [loadingData, setLoadingData] = useState(false);
    const [showWonBanner, setShowWonBanner] = useState(false);

    // data state
    const [info, setInfo] = useState();
    const [lines, setLines] = useState();
    // const [lineInfo, setLineInfo] = useState();
    
    // streak state
    const [currentStreak, setCurrentStreak] = useState(0);
    const [maxStreak, setMaxStreak] = useState(0);
    const [dailyStreak, setDailyStreak] = useState([]);
    const [showPromptUserModal, setShowPromptUserModal] = useState(false);

    // game state
    const [won, setWon] = useState();
    
    // session counters
    const [sessionCount, setSessionCount] = useState(0);

    // console logs
    // console.log('lines: ', lines);
    // console.log('info: ', info);

    // get width of device user is on
    // const innerWidth = window.innerWidth;
    // const mobileScreen = (innerWidth < 550) ? true : false;
    // console.log('mobileScreen: ', mobileScreen);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    // get data from S3
    useEffect(() => {
        setLoadingData(true);

        // get data from s3 bucket and initialise selectedTypes with all types available
        const fetchData = async () => {
            try {
                const res = await axios.get(AWS_API + 'data');
                // console.log('response: ', res.data);
                setLines(res.data.lines);
                setInfo(res.data.info);

                // get unique list of types available
                const availableTypes = res.data.lines.map(obj => obj.type).filter((v, i, a) => a.indexOf(v) === i);

                // remove a couple of types to make it obvious which are selected/unselected
                availableTypes.splice(availableTypes.indexOf('quote'),1);
                availableTypes.splice(availableTypes.indexOf('book'),1);

                // setSelectedTypes(availableTypes);
            } catch (err) {
                console.log('error whilst fetching data: ', err);
            } finally {
                setLoadingData(false);
            }   

        }

        fetchData();
        

    }, [setInfo, setLines]);

    // get streaks and settings from local storage and put into state
    useEffect(() => {
        const localCurrentStreak = localStorage.getItem("current-streak");
        const localMaxStreak = localStorage.getItem("max-streak");
        const localDailyStreak = localStorage.getItem("daily-streak");


        setCurrentStreak(localCurrentStreak ?? 0);
        setMaxStreak(localMaxStreak ?? 0);
        setDailyStreak(localDailyStreak ?? []);
        console.log('Set current streak from storage to: ', localCurrentStreak ?? 0);
        console.log('Set max streak from storage to: ', localMaxStreak ?? 0);
        // console.log('Set daily streak from storage to: ', localDailyStreak ?? []);
    }, [])

    // calculate days in daily streak any time daily streak changes and pass value up to Layout
    useEffect(() => {

        calculateDailyStreak(props.setDaysInStreak, dailyStreak);

    }, [dailyStreak, props]);
    
    // increment current streak and update streaks in local storage
    const incrementStreak = () => {
        
        // only increment streak once per correct answer
        if (!won) {
            const newCurrentStreak = +currentStreak + 1;
            const oldMaxStreak = localStorage.getItem("max-streak") ?? 0;
            if (newCurrentStreak > oldMaxStreak) {
                localStorage.setItem("max-streak", newCurrentStreak);
                setMaxStreak(newCurrentStreak);
            }
            setCurrentStreak(newCurrentStreak);
            localStorage.setItem("current-streak", newCurrentStreak);
        }
    }    

    const handleWin = () => {
        
        // only do these things once per correct answer
        if (!won) {


            // increment session count each time question answered (correctly or incorrectly)
            setSessionCount(sessionCount + 1);            
            
            // add today to daily streak counter if not already
            const localDailyStreak = JSON.parse(localStorage.getItem("daily-streak")) ?? [];
            const today = new Date().toLocaleDateString('sv-SW');
            // console.log('localDailyStreak: ', localDailyStreak);
            // console.log('today: ', today);
            if (!localDailyStreak.includes(today)) {
                localDailyStreak.push(today);
                localStorage.setItem("daily-streak", JSON.stringify(localDailyStreak));
                // also update in state
                setDailyStreak(localDailyStreak);
                // console.log('localDailyStreak: ', localDailyStreak);
            }
            

            incrementStreak();
            setWon(true);
            setShowWonBanner(true);
            setTimeout(() => {
                setShowWonBanner(false);
            }, 750);        
            
        }
    }    

    // any time session count gets above 5, if we haven't already prompted user for feedback or to follow on insta, do so
    useEffect(() => {
        // console.log('checking session count, count = ', sessionCount);
        if (sessionCount > 4) {
            const promptedUser = localStorage.getItem("prompted-user-v2") ?? 'no';
            // console.log('prompted user from local storage: ', promptedUser);
            if (promptedUser !== 'yes') {
                setShowPromptUserModal(true);
                localStorage.setItem("prompted-user-v2", 'yes');
            }
        }
    }, [sessionCount])

    // reset current streak
    const resetStreak = () => {
        setCurrentStreak(0);
        localStorage.setItem("current-streak", 0);
    };    

    let banner;
        if (true) {
        banner = <div className={showWonBanner ? [classes.Banner, classes.BannerVisible].join(' ') :  [classes.Banner, classes.BannerHidden].join(' ') }>Nice!</div>
        // banner = <div className={ [classes.Banner, classes.BannerVisible].join(' ') }>Nice!</div>
    }  


    let tabContent = <div style={{padding: '100px 0'}}> <Spinner /> </div>
    if (!loadingData) {
        if (tab === 'quotes') {
            tabContent = <Quotes handleWin={handleWin} lines={lines} info={info} currentStreak={currentStreak} 
            maxStreak={maxStreak} won={won} setWon={setWon} resetStreak={resetStreak} setSessionCount={setSessionCount} sessionCount={sessionCount} />
        } else if (tab === 'languages') {
            tabContent =  <Accents mode='guessLanguage' handleWin={handleWin} lines={lines} info={info} currentStreak={currentStreak} 
            maxStreak={maxStreak} won={won} setWon={setWon} resetStreak={resetStreak} setSessionCount={setSessionCount} sessionCount={sessionCount}/> 
        } else if (tab === 'accents') {
            tabContent =  <Accents mode='guessAccent' handleWin={handleWin}  lines={lines} info={info} currentStreak={currentStreak} 
            maxStreak={maxStreak} won={won} setWon={setWon} resetStreak={resetStreak} setSessionCount={setSessionCount} sessionCount={sessionCount}/> 
        } else {
            tabContent = <p>Game mode not supported.</p>
        }


    }

    return (
        <div className={classes.Modes}>
            <Modal show={modalMessage} modalClosed={() => setModalMessage()}>
                <h5>Oops, something isn't right...</h5>
                <hr/>
                <p>{modalMessage}</p>
            </Modal>             
            <Modal show={showPromptUserModal} modalClosed={() => setShowPromptUserModal(false)}>
                {/* <h5>Hi there!</h5> */}
                <hr/>
                <Socials />
                <hr/>
                <h6>Or let us know your ideas for improvement <Link to='about'>here</Link> </h6>
            </Modal>  
            <Box sx={{ width: '100%' }}>
                <Tabs
                    // TabIndicatorProps={{style: {background:'#F5881F', color: 'black'}}}
                    value={tab}
                    onChange={handleTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="quotes" label={<span>Quotes</span>} />
                    <Tab value="languages" label={<span>Languages</span>} />
                    <Tab value="accents" label={<span >Accents</span>} />
                </Tabs>
            </Box>       
            <p> </p>      
            {tabContent}
            {banner}
                
        </div>

    );
}

export default Modes;