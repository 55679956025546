import React from 'react';

import classes from './TopDrawer.module.css';
// import Aux from '../../hoc/Auxiliary/Auxiliary';

const TopDrawer = (props) => {

    // let attachedClasses = [classes.Row, classes.Close];
    let attachedClasses = [classes.Close];
    let topDrawerClasses = [classes.TopDrawer];

    if (props.open) {
        // attachedClasses = [classes.Row, classes.Open];
        attachedClasses = [classes.Open];
        topDrawerClasses = [classes.TopDrawer, classes.TopDrawerOpen];
    }

    // let buttons = (
    //     <Aux>
    //         <button onClick={props.toggle} className={classes.Button}>
    //             <b style={{fontSize: '1.2rem'}}>{props.open ? '▲ ' : '▼ '}</b> 
    //             Included Models
    //         </button>
    //     </Aux>
    // );

    // const titleSymbol = props.open ? '▲ ' : '▼ ';
    
    return (
        // <div className={classes.Container}>
            <div className={topDrawerClasses.join(' ')}>
                <div onClick={props.toggle} className={classes.Title}>
                    {props.open ? <span>&#x25BC; </span> : null} 
                    {!props.open ? <span>&#x25B6; </span> : null} 
                    <span>&nbsp;</span> 
                    <span >{props.title}</span>
                    
                    {/* <span> {titleSymbol}</span> */}
                </div>
                <div className={attachedClasses.join(' ')}>
                    {/* <hr /> */}
                    {props.children}
                </div>
            </div>

        // </div>
    );
};

export default TopDrawer;