import React, { useState } from 'react';
import classes from './About.module.css';
import { AWS_API } from '../../config/config';
import axios from 'axios';
import SpinnerDark from '../../UI/SpinnerDark/SpinnerDark';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Socials from '../../hoc/Socials/Socials';
// import Coffee from '../../hoc/Coffee/Coffee';

const About = () => {

    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState();
    const [feedbackDone, setFeedbackDone] = useState(false);

    const writeFeedback = async () => {
    // function to pass into AdminPin component to validate user-entered Kiosk admin pin server-side
        setLoading(true);

        // const headers = {
        //     headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        // };
        const postData = {
            email: email,
            feedback: feedback
        };

        try {
            const res = await axios.post(AWS_API + 'feedback', {postData: postData});
            console.log(res.data);
            setFeedback('');
            setEmail('');
            setFeedbackDone(true);
        } catch (err) {
            // console.log('error: ', err);
        } finally {
            setLoading(false);
        }   
    }

    let feedbackForm = < SpinnerDark />;
    if (!loading) {
        feedbackForm = (
            <Aux>
            <hr />
            <strong><p>Your feedback is welcome!</p></strong>                
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="text" placeholder="Email (optional)" />
                <p> </p>
                <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} className="form-control" type="text-area" rows="4" placeholder="Enter your feedback here!" />
                <p> </p>
                <button className="btn btn-outline-warning btn-block" onClick={writeFeedback}>Submit</button>
            </Aux>
        );
    }

    if (feedbackDone) {
        feedbackForm = (
            <p><strong>Thanks for your feedback!</strong></p>
        );
    }

    return (
        <div className={classes.About}>
            <h2>About QMQF</h2>
            <hr />
            <p>Quotey McQuoteFace is designed to help you improve listening comprehension in your chosen language through fun quotes from pop culture.</p>

            {feedbackForm}
            <hr />
            <Socials />
            {/* <Coffee /> */}
            {/* <p className={classes.MakeLogo}>Q</p> */}
        </div>
    );
}

export default About;